import { MDBCol, MDBContainer, MDBRow } from 'mdbreact';
import React from 'react'; 
import { MDBBtn, MDBIcon } from 'mdbreact';

function Social() { 
    return ( 
            <MDBRow> 
            <MDBCol md="1" />
                <MDBCol md="10"> 
                <MDBBtn href="https://www.linkedin.com/in/jackgibson1603/ "color="primary" size="lg" social="li">
                  <MDBIcon fab icon="linkedin-in" />
                </MDBBtn>
                <MDBBtn href="https://github.com/jackgibson1 "color="elegant" size="lg" social="git">
                  <MDBIcon fab icon="github" />
                </MDBBtn>
                <MDBBtn href="mailto:jackgibson1603@gmail.com" size="lg" social="email">
                  <MDBIcon icon="envelope" />
                </MDBBtn>
                </MDBCol>
            <MDBCol md="1" />
            </MDBRow>
    )
}
export default Social;