import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import ProjectCard from './ProjectCard';
import ProjectList from './ProjectList';

function Projects() {
    return (
        <MDBContainer className="About text-center mt-2">
            <MDBRow>
                <MDBCol md="1" />
                <MDBCol md="10">
                    <h2><strong>Projects</strong></h2>
                    <p className="text-center pt-3">
                        Here's a few of my latest projects:
        </p>
                    {ProjectList.map((project) => { 
                        return (
                            <ProjectCard content={project} />
                 
                        )
                    })}
                    
                </MDBCol>
                <MDBCol md="1" />
            </MDBRow>
        </MDBContainer>
    )



}
export default Projects;