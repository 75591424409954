import React, { useState } from 'react';
import { useParams} from 'react-router-dom'; 
import { MDBContainer } from 'mdbreact';
import BlogPosts from './BlogPosts';

const styles = { 
    header: { 
        marginTop: '80px'
    }
}


function BlogPost() { 
    const { id } = useParams(); 
    const blogPost = BlogPosts.find(bp => bp.id === Number(id));

    return ( 
        <MDBContainer>
            <h1 style={styles.header}>{blogPost.title}</h1>  
            <p>{blogPost.content}</p> 
            {blogPost.component}
        </MDBContainer>
    )
}

export default BlogPost;