import React from 'react';
import { MDBIcon, MDBCardTitle, MDBBtn, MDBCard } from 'mdbreact';


function ProjectCard(props) {
    const { content } = props;
    const backgroundImage = content.background;

    return (
       <MDBCard
          className='card-image mb-5'
          style={{
            backgroundImage: backgroundImage
          }}
        >
          <div className='text-white text-center d-flex align-items-center py-5 px-4'>
            <div>
              <h5 className='orange-text'>
                <MDBIcon icon='desktop' /> 
              </h5>
              <MDBCardTitle tag='h3' className='pt-2'>
                <strong>{content.title1}</strong>
              </MDBCardTitle>
              {content.title2 && (
                    <MDBCardTitle tag='h3' className='pt-2'>
                    <strong>{content.title2}</strong>
                  </MDBCardTitle>
              )}
              
              <p>
                {content.about}
              </p>
              <MDBBtn disabled={content.disabled} href={content.link} color='deep-orange'>
                <MDBIcon icon='clone left' /> View project
              </MDBBtn>
            </div>
          </div>
        </MDBCard>
    )
}
export default ProjectCard;