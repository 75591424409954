import BlogPost2 from './IndividualPostComponents/BlogPost2';

const BlogPosts = [ 
    { id: 1, 
      name: 'Jack Gibson', 
      title: 'Welcome - First Blog Post!', 
      content: 'I thought I should introduce myself and explain why I decided to make a website. I’ve always had a keen interest in trying new things in order to gain skills and further my knowledge. As a software engineering intern at Allstate NI I build enterprise full stack applications using React.js, MySQL and Spring. My passion for web apps has resulted in me challenging myself to design my own website! I made this using React, Node.js (with express) and MySQL for storage. It is currently hosted on Amazon Web Services. My aim is to constantly improve this website each week, whether that be with new sections or design tweaks! Thanks for reading my first chaotic post, I’ll be back next week!', 
      date: '07/03/2021', 
      imgpath: 'images/post1.jpg', 
      readmore: false,
    }, 
    { id: 2, 
      name: 'Jack Gibson', 
      title: 'Building A Web App - My Learnings', 
      content: 'In this post I cover how I went about building this website. I split the entirity of the process into three main sections; building the frontend using React.js, building the backend using Node.js and deploying the app from a local environment to an AWS production environment. I also touch upon the current deployment pipeline structure for future changes.',  
      date: '22/06/2021', 
      component: <BlogPost2 />,
      imgpath: 'images/BlogPost2/post2.jpeg' , 
      readmore: true
    }
]

export default BlogPosts;