import React from 'react'; 
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import Social from './Social';

function About() { 
    return ( 
        <MDBContainer className="About text-center mt-5">
        <MDBRow>
          <MDBCol md="1" />
          <MDBCol md="10">
            <h2><strong>About Me</strong></h2>
            <p className="text-center pt-3">
              I am currently 21 years of age, studying Computer Science at Queen's University Belfast. At the moment I am completing a one year long internship
              with Allstate Northern Ireland. In this role I am Software Engineer focusing on full stack web development. I am very passionate about building 
              meaningful and exciting technology whether that be in work or outside it. In 2019 I also completed a three month summer internship with Arity as a DevOps Engineer. 
              Thanks for taking the time to check out this page and feel free to checkout my social media platforms below.
            </p>
          </MDBCol>
          <MDBCol md="1" />
        </MDBRow>
        <Social />
        </MDBContainer>

    )
}
export default About;