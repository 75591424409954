import React from 'react'; 
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';

function Tech() { 
    return ( 
        <MDBContainer className="Tech text-center mt-2 mb-2">
          <MDBRow>
            <MDBCol md="1" />
            <MDBCol md="10">
            <h5 className="text-left pt-3">
            <strong>Languages</strong>
            </h5>
            </MDBCol>
            <MDBCol md="1" />
          </MDBRow>

          <MDBRow>
            <MDBCol size="4" sm="4"> 
            <p>JavaScript</p>
            </MDBCol>
            <MDBCol size="4" sm="4"> 
            <p>Java</p>
            </MDBCol>
            <MDBCol size="4" sm="4"> 
            <p>C++</p>
            </MDBCol>
          </MDBRow> 

          <MDBRow>
            <MDBCol md="1" />
            <MDBCol md="10">
            <h5 className="text-left pt-3">
            <strong>Technologies & Frameworks</strong>
            </h5>
            </MDBCol>
            <MDBCol md="1" />
          </MDBRow>

          <MDBRow>
            <MDBCol size="4" md="4"> 
             <p>React</p>
             <p>Node.js</p>
             <p>Express</p>
            </MDBCol>
            <MDBCol size="4" md="4"> 
             <p>Jest & Enzyme</p>
             <p>REST</p>
             <p>MySQL</p>
            </MDBCol>
            <MDBCol size="4" md="4"> 
            <p>phpMyAdmin</p>
             <p>AWS</p>
             <p>Linux</p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>

    )
}
export default Tech;