import React from 'react'; 
import { MDBMask, MDBView } from 'mdbreact';

function View() { 
    return ( 
        <MDBView src="https://mdbootstrap.com/img/Photos/Others/img%20(40).jpg">
        <MDBMask className="flex-center flex-column text-white text-center">
          <h2>Hey, I'm Jack Gibson</h2>
          <h5>Developer & Student</h5>
        </MDBMask>
      </MDBView>
    )
}
export default View;