import React from 'react'; 
import About from './About';
import View from './View';
import Tech from './Tech';
import Projects from './Projects';

function Main() { 
    return ( 
      <main>
        <View />
        <About />
        <hr className="ml-4 mr-4" />
        <Tech />
        <hr className="ml-4 mr-4" />
        <Projects />
      </main>
    )
}
export default Main;