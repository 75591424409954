import React from 'react'
import { MDBRow, MDBCol, MDBMask, MDBView, MDBBtn } from "mdbreact";
import { useHistory } from 'react-router-dom';

function BlogRow(props) { 
  const history = useHistory();

  const { postData } = props;

    return ( 
        <MDBRow className="mb-5">
        <MDBCol lg="5" xl="4">
          <MDBView hover className="rounded z-depth-1-half mb-lg-0 mb-4">
            <img
              className="img-fluid"
              src={postData.imgpath}
              alt=""
              height="200px"
            />
            <a href="#!">
              <MDBMask overlay="white-slight" />
            </a>
          </MDBView>
        </MDBCol>
        <MDBCol lg="7" xl="8">
          <h3 className="font-weight-bold mb-3 p-0">
            <strong>{postData.title}</strong>
          </h3>
          <p className="dark-grey-text">
            {postData.content}
          </p>
          <p>
            by <a href="https://jackgibson.uk" className="font-weight-bold text-primary">{postData.name}</a>, {postData.date}
          </p>
          <MDBBtn disabled={!postData.readmore} color="primary" size="md" onClick={() => history.push(`/blog/${postData.id}`)}> 
            Read More
          </MDBBtn>
        </MDBCol>
      </MDBRow>
    )
}
export default BlogRow