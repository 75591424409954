import React, { useEffect, useState } from "react";
import BlogPosts from './BlogPosts';
import { MDBRow, MDBCol, MDBCard, MDBCardBody, MDBMask, MDBIcon, MDBView, MDBBtn, MDBContainer } from "mdbreact";
import BlogRow from './BlogRow';

const BlogPage = () => {

  return (
    <MDBCard className="mt-5 px-3">
      <MDBCardBody>
        <h2 className="h1-responsive font-weight-bold text-center my-5">
          Recent posts
        </h2>
        <p className="text-center w-responsive mx-auto mb-5">
          Hey! Welcome to my blog! I'll try post a paragraph or two once a week on anything and everything. Hope you enjoy!
        </p>
        {BlogPosts.map((postData, index) => ( 
          <MDBContainer key={index}>
              <hr className="my-5" />
              <BlogRow postData={postData} />
          </MDBContainer>
        ))}
      </MDBCardBody>
    </MDBCard>
  );
}

export default BlogPage;