import React, { useState } from 'react';
import { MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavbarToggler, MDBCollapse, MDBNavItem, MDBNavLink } from 'mdbreact';

function Header()  {
    const [ collapse, setCollapse ] = useState(false);
    const [isWideEnough, setIsWideEnough ] = useState(false);

    function onClick() { 
        setCollapse(!collapse);
    }

    return (
        <header>
            <MDBNavbar color="white" fixed="top" dark expand="md" scrolling>
              <MDBNavbarBrand className="black-text" href="/">
                <strong>Jack Gibson</strong>
              </MDBNavbarBrand>
              {!isWideEnough && <MDBNavbarToggler className="black" onClick={onClick} />}
              <MDBCollapse isOpen={collapse} navbar>
                <MDBNavbarNav left>
                  <MDBNavItem active>
                    <MDBNavLink className="black-text" to="/" onClick={onClick}>Home</MDBNavLink>
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBNavLink className="black-text" to="/blog" onClick={onClick}>Blog</MDBNavLink>
                  </MDBNavItem>
                </MDBNavbarNav>
              </MDBCollapse>
            </MDBNavbar>
        </header>
    );
}

export default Header;