const ProjectList = [
    {
        title1: 'YouTube Clone',
        title2: '(React Native)',
        background: "url('https://images.unsplash.com/photo-1523800503107-5bc3ba2a6f81?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1100&q=80')",
        about: "Mobile development is always something that has interested me. Having React.js experience I thought it would be best to try and learn React Native. Check out my progress on this YouTube clone so far!",
        link: "https://github.com/jackgibson1/YouTube-Clone",
        disabled: false
    },
    {
        title1: 'Blog Posts API',
        title2: '(Node.js + Express)',
        background: "url('https://images.unsplash.com/photo-1615227777158-3de68180af11?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=934&q=80')",
        about: "For this website I needed to spin up a robust API and web server. Node.js with Express allowed me to create a simple endpoint in which I could call in order to display the blog posts. It's minimal and lightweight. I kept the code private for security reasons.",
        link: "",
        disabled: true
    }
]
export default ProjectList;