import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";

function FooterPage() {
  return (
    <MDBFooter color="black" className="font-small">
      <div className="footer-copyright text-center pt-3">
        <MDBContainer fluid>
            <p>&copy; {new Date().getFullYear()} Copyright: <a href=""> Jack Gibson </a></p>
            <p>Made using React.js, Node.js (with Express) and MySQL.</p>
        </MDBContainer>
      </div>
    </MDBFooter>
  );
}

export default FooterPage;